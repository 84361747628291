.radius-8 {
    border-radius: 8px !important;
}
.radius-12 {
    border-radius: 12px !important;
}
.elipse-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}
.w-max-content {
    width: max-content;
}
.pt-content {
    padding-top: 68px;
}
.h-100vh {
    height: 100vh;
}
.min-h-screen {
    min-height: 100vh;
}
.w-50 {
    width: 50%;
}
.w-30 {
    width: 30%;
}
.w-35 {
    max-width: 350px;
}
.mt-100 {
    margin-top: 100px;
}
.mb-100 {
    margin-bottom: 100px;
}
