.text-grey {
    color: var(--grey-text-login) !important;
}
.text-error {
    color: var(--red) !important;
}
.text-black {
    color: var(--black-text) !important;
}
.text-primaryy {
    color: var(--primary) !important;
}
.bg-msecondary {
    background-color: var(--secondary) !important;
}
.bg-light-grey {
    background-color: var(--grey-border);
}
