@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

:root {
    --grey-text-login: #4f4f4f;
    --primary: #f09856 !important;
    --primary-light: #ffbe9d;
    --bold-blue: #262f56;
    --secondary: #f5f0dd !important;
    --grey-border: #bdbdbd;
    --black-text: #282828;
    --orange-badge: #f2994a;
    --red: #eb5757;
    --primary-hover: #ee9e61 !important;
    --bold-blue-hover: #141931 !important;
}
.MuiTableCell-root {
    font-family: 'Inter', sans-serif !important;
}
* {
    font-family: 'Inter', sans-serif;
    margin: 0;
}

.wrap-greet h1 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
    color: var(--grey-text-login);
}
.wrap-greet p {
    color: var(--grey-text-login);
}
.wrap-greet .btn-google {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: var(--grey-text-login);
}
html,
body,
#root {
    min-height: 100%;
    background: url('../images/bg_pattern.png');
    overflow: auto !important;
    min-width: 100% !important;
}

.text-salmon {
    color: var(--primary) !important;
}

.bg-auth {
    background-color: var(--primary);
}

/* LOGIN */
.container-auth {
    min-height: 100vh;
    display: block;
}
.container-auth .container-ilustration {
    width: 75%;
    background: var(--primary);
    visibility: hidden;
    display: none;
}
.container-auth .text-hallo {
    margin-top: 50px;
    font-weight: bold;
    font-size: 32px;
    color: var(--grey-text-login);
}
.container-auth .text-desc {
    font-size: 14px;
    color: var(--grey-text-login);
    max-width: 600px;
}

.container-auth .btn-google {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: var(--grey-text-login);
    font-weight: 600;
    background-color: #fff;
}
.container-auth .container-or .line {
    height: 1px;
    background: #fff;
}
.container-auth .container-or p {
    font-size: 14px;
    color: var(--grey-text-login);
    top: -10px;
}
.container-auth .checkbox-text {
    font-size: 14px;
    color: var(--grey-text-login);
}
.container-auth .checkbox-text b {
    color: var(--primary);
}
/* FORM */
.form label {
    font-weight: bold;
    color: var(--grey-text-login);
    font-size: 15px;
}
.btn-auth {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
    background-color: #fff !important;
    color: var(--primary) !important;
}
.bg-login {
    object-fit: cover;
    height: 690px;
}
.layout-container {
    padding-top: 80px;
}
.label-login {
    font-size: 16px;
    color: var(--grey-text-login);
    font-weight: 600;
}
.form-control-costum {
    padding: 16px 24px;
    height: 100%;
    border: 1px solid var(--border-login);
}
.btn-masuk {
    background-color: var(--primary);
    font-size: 18px;
    color: var(--white);
    border-radius: 8px;
}
.btn-masuk:hover {
    color: var(--white);
}
.form-check {
    display: flex;
    align-items: center;
}
.form-check input {
    width: 24px;
    height: 24px;
}
.form-check label {
    margin-left: 16px;
    color: var(--grey-text-login);
}
.text-forgot {
    color: #fff;
    font-size: 14px;
}
/* .text-forgot:hover {
    color: #fff;
    text-decoration: none;
} */

/* SIDEBAR */
.menu-sidebar {
    text-decoration: none !important;
    border-radius: 8px !important;
    color: var(--bold-blue);
}
.menu-sidebar img {
    width: 20px;
}
.menu-sidebar.active {
    background-color: var(--primary-light) !important;
}
.menu-sidebar:hover {
    text-decoration: none !important;
    background-color: var(--primary-light) !important;
}
.menu-sidebar .menu-text span {
    font-weight: 600;
    font-size: 15px;
}
.btn-profile:hover {
    background-color: transparent !important;
}

/* MOBILE NAV */
.menu-mobile {
    bottom: 0px;
    width: 100%;
    background-color: var(--primary);
}
.menu-mobile .item-menu {
    width: 45px;
    height: 45px;
    border-radius: 45px;
}
.menu-mobile .item-menu p {
    font-size: 10px;
    color: var(--black-text);
}
.menu-mobile .item-menu:hover {
    text-decoration: none;
}
.menu-mobile .item-menu p.active {
    color: #fff;
}
.menu-mobile .item-menu.active {
    background-color: var(--primary);
}
.menu-mobile .item-menu.find-friend {
    border-radius: 45px;
    width: 45px;
    height: 45px;
    background-color: var(--primary);
    margin-top: -40px;
    border: 10px solid #fff;
    box-sizing: content-box;
}
.menu-mobile .item-menu img {
    width: 24px;
}

/* PROFILE */
.card-profile .container-info {
    padding-left: 1rem;
    padding-right: 1rem;
}
.card-profile .container-info .container-row {
    text-align: center;
}
.card-profile .container-info .img-profile {
    width: 100px;
    height: 100px;
    border-radius: 100px;
}
.card-profile .container-info .btn-edit-profile {
    box-shadow: none;
    height: 35px;
    width: 100%;
}
.card-interest.profile .container-interest {
    max-height: max-content;
    overflow: visible;
}
.card-interest.profile .item-interest {
    margin-bottom: 15px !important;
}
.card-interest.profile .item-interest p {
    font-size: 14px;
}
.container-interest {
    max-height: 23px;
    overflow: hidden;
}
.container-interest .item-interest {
    background-color: var(--secondary);
    border-radius: 4px;
}
.container-interest .item-interest p {
    font-weight: 600;
    font-size: 10px;
}
.text-forgot a {
    color: var(--grey-text-login);
    text-decoration: none;
    font-size: 14px;
}
.text-forgot a:hover {
    color: var(--grey-text-login);
    text-decoration: none;
}

/* navbar */
.img-user-dropdown {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.wrap-navbar {
    height: auto;
    background-color: var(--white);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
}
a.link-custom {
    font-size: 16px;
    margin: 3px;
    padding: 8px;
}
a.link-custom.active {
    font-weight: 500;
    color: var(--primary) !important;
}
a.link-custom.active:hover {
    font-weight: 500;
    color: var(--primary) !important;
}
.name-user-dropdown {
    font-size: 14px;
    color: var(--bold-blue);
}
.dropdown-toggle {
    color: var(--bold-blue);
}
.dropdown-toggle::after {
    color: var(--bold-blue);
}

/* card complete profile */
.btn-next {
    background-color: var(--primary) !important;
    color: #fff !important;
    font-size: 16px;
}
.btn-back {
    background-color: #fff !important;
    color: var(--grey-text-login) !important;
    border: 1px solid var(--grey-border) !important;
    font-size: 16px;
}
.card-complete-profile {
    margin: 32px 0;
    box-shadow: 0px 4px 20px rgba(9, 44, 76, 0.05);
    border: none !important;
}
.strepper-custome .MuiStepIcon-text {
    fill: var(--black-text) !important;
    font-weight: bold;
}
.strepper-custome .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 10px;
}
/* input file */
.wrap-border-file {
    border: 1px solid #9999;
    border-radius: 4px;
    padding: 32px;
}
._input-file {
    position: relative;
    width: 100%;
    background: #fff;
    border: 2px dashed var(--grey-border);
    border-radius: 4px;
}
._input-file:hover {
    cursor: pointer !important;
}
._input-file input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
}
._input-file p {
    color: var(--grey-text-login);
}
._input-file .placeholder-file {
    font-size: 14px;
    color: var(--grey-text-login);
    margin: 12px 0 0;
}
._input-file .container-preview::after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
._input-file .img-preview {
    border-radius: 4px;
    max-height: 200px;
    min-height: 100px;
    object-fit: cover;
}
._input-file .preview-active .MuiSvgIcon-root,
._input-file .preview-active .placeholder-file {
    color: #fff !important;
}

._input-file .content {
    height: 150px;
    width: 50px;
}

/* card topic */
.wrap-desc-interest {
    padding: 16px 32px;
    background-color: var(--primary-light);
    border-radius: 4px;
}
.wrap-desc-interest p {
    margin: 0;
    font-size: 14px;
}
.wrap-title-interest {
    margin-top: 24px;
}
.btn-interest {
    border: 1px solid #f4f4f4;
    width: 100%;
    font-size: 14px;

    background-color: #fff;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.btn-interest:hover {
    background-color: rgba(240, 152, 86, 0.6);
}
.btn-interest.active {
    color: #fff;
    background-color: var(--primary);
    border: none;
}

/* select input */
.css-g1d714-ValueContainer {
    padding: 10px 16px !important;
}
.css-g1d714-ValueContainer .css-1uccc91-singleValue {
    font-size: 15px;
}

/* Matched friends */
.bar-custom {
    background-color: transparent !important;
    box-shadow: none !important;
    border-bottom: 1px solid var(--grey-border);
}
.bar-custom button {
    color: var(--grey-border);
    font-weight: bold;
    padding: 19px 10px 8px;
    font-size: 18px;
}
.bar-custom button.Mui-selected {
    color: var(--bold-blue);
    font-weight: bold;
}
.bar-custom span.PrivateTabIndicator-colorSecondary-13 {
    background-color: var(--bold-blue) !important;
}
.container-tab-bar .MuiTabs-flexContainer {
    justify-content: space-around;
}
.box-custom {
    margin: 24px 0 16px;
}
.card-match {
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.desc-user h6 {
    margin-bottom: 4px;
    font-weight: 600;
    color: var(--grey-text-login);
    font-size: 18px;
}
.desc-user p {
    margin-bottom: 4px;
    font-weight: 400;
    color: var(--grey-text-login);
    font-size: 14px;
}
.buttons-topic div {
    padding: 4px 12px;
    background-color: var(--secondary);
    margin: 4px;
    border-radius: 4px;
    font-size: 10px;
    color: #fff;
}
.btn-action {
    background-color: var(--primary);
    margin: 4px;
    padding: 15px;
    border-radius: 8px;
}

.title-page {
    color: var(--bold-blue);
    font-size: 24px;
    border-bottom: 1.5px solid var(--grey-border);
    padding-bottom: 10px;
}
.bar-custom .MuiTab-root {
    text-transform: none !important;
}

/* FIND FRIEND */
.card-friend .img-profile {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
}
.card-friend .container-content .btn-card-friend {
    font-size: 12px;
}

.card-friend .user-info {
    flex: 1;
    margin: 0px 0px 0px 15px;
}
.card-friend .user-info .name {
    font-size: 15px;
    color: var(--grey-text-login);
    margin-bottom: 10px;
}
.card-friend .user-info .subtext {
    font-size: 12px;
    color: var(--grey-text-login);
    margin-bottom: 4px;
}
.card-friend .container-profile {
    width: 50px;
    height: 50px;
}
.card-suggest-topic .title {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-text);
}
.card-suggest-topic .subtitle {
    font-size: 10px;
    font-weight: 500;
    color: var(--grey-text-login);
}
.card-suggest-topic .btn-add {
    width: 35px;
    height: 35px;
}
.card-suggest-topic .btn-add {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 8px;
    background: var(--primary);
    color: #fff;
}
.title-side-section {
    font-weight: bold;
    font-size: 18px;
    color: var(--black-text);
}
.container-suggest {
    display: none;
}
.btn-card {
    width: 45px;
    height: 45px;
    padding: 0px;
    background: #fff;
    transition: 0.2s;
}
.btn-card.btn-unfollow {
    border: 1px solid var(--red);
}
.btn-card.btn-unfollow:hover {
    background-color: #ffe4e4;
}
.btn-card.btn-chat {
    background-color: var(--primary);
    border: none;
}
.btn-card.btn-chat:hover {
    background-color: var(--primary);
}
.border-error {
    border: 1px solid var(--red) !important;
}
.btn-outline-error {
    background-color: #fff;
}
.btn-outline-error:hover {
    background-color: #ffe4e4 !important;
}

/* CARD FRIEND SUGGEST */
.card-friend-suggest .img-profile {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
}
.card-friend-suggest .user-info .name {
    font-size: 14px;
    color: var(--grey-text-login);
    margin-bottom: 10px;
}
.card-friend-suggest .user-info .subtext {
    font-size: 12px;
    color: var(--grey-text-login);
    margin-bottom: 4px;
}
.card-friend-suggest .container-profile {
    width: 40px;
    height: 40px;
}
.card-friend-suggest button {
    font-size: 10px;
}

/* MESSAGE */
.card-chat .profile {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
}
.card-chat .content .nama {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-text);
    margin: 0px;
}
.card-chat .content .waktu {
    font-size: 12px;
    color: var(--grey-text-login);
    margin: 0px;
}
.card-chat .content .message {
    font-size: 14px;
    color: var(--grey-text-login);
    margin: 0px;
    max-width: 80%;
    word-break: break-all;
}

/* HOME */
.card-home .title {
    font-size: 20px;
    color: var(--black-text);
}
.card-home .more {
    font-size: 16px;
    color: var(--primary);
}
.container-home {
    margin-top: 50px;
}
.container-home .container-content {
    max-width: 450px;
}
.container-home .title {
    font-size: 18px;
}
.container-home .subtitle {
    max-width: 500px;
    font-size: 12px;
    margin: auto;
}
/* CONFERENCE */
.card-conference {
    flex-direction: column;
    align-items: flex-end;
}
.card-conference .title {
    color: var(--black-text);
    font-size: 18px;
    margin-bottom: 8px;
}
.card-conference .subtitle {
    color: var(--black-text);
    font-size: 15px;
    margin-bottom: 8px;
}
.btn-create-group {
    background-color: var(--primary) !important;
    border: none !important;
    font-weight: bold !important;
}
.btn-create-group:hover {
    background-color: var(--primary-hover) !important;
}
hr {
    background-color: var(--grey-border);
}
.btn-participant {
    background-color: var(--primary) !important;
    border: none !important;
    font-weight: 600 !important;
    font-size: 13px !important;
}
.btn-participant:hover {
    background-color: var(--primary-hover) !important;
}

/* chat */
.content-chat {
    background-color: #fbf7f1;
    padding: 12px 24px 20px 24px;
    border-radius: 8px 8px 0 8px;
    min-width: 294px;
}
.content-chat p,
.send-chat p {
    word-break: break-all;
    white-space: pre-wrap;
    line-height: 1.4rem;
}
.content-chat,
.send-chat {
    line-height: 28px;
    max-width: 90%;
    position: relative;
}
.content-chat span {
    color: var(--grey-text-login);
    font-size: 10px;
    bottom: 0;
    right: 12px;
    position: absolute;
}
.send-chat {
    background-color: var(--primary);
    padding: 12px 24px 20px 24px;
    border-radius: 8px 8px 8px 0;
    min-width: 294px;
}
.send-chat p {
    color: #fff;
    font-size: 14px;
}
.send-chat span {
    display: flex;
    flex-flow: column-reverse;
    color: var(--grey-text-login);
    font-size: 10px;
    bottom: 0;
    right: 12px;
    position: absolute;
}
.btn-title {
    border: none;
    background-color: #fff;
    padding: 0;
}

/* Modal Dialog */
img.img-trash {
    background-color: var(--primary-light);
    border-radius: 50px;
    padding: 22px;
    margin: auto;
    display: flex;
}

/* badge notif */
span.badge-custom {
    background-color: var(--orange-badge);
    color: #fff;
    border-radius: 30px;
}
/* NOT FOUND */
.container-not-found img {
    max-width: 500px;
}
.container-not-found .btn-back {
    background-color: var(--primary) !important;
    font-size: 13px;
}
.btn-confirm-no {
    background-color: var(--primary);
    color: #fff;
    border: none;
    padding: 14px 54px;
    font-size: 14px;
    border-radius: 8px;
}
.btn-confirm-no:hover {
    background-color: var(--primary-hover);
}
.btn-confirm-yes {
    background-color: #fff;
    border: 1px solid var(--red);
    color: var(--red);
    padding: 14px 54px;
    font-size: 14px;
    border-radius: 8px;
}
.btn-edit-no {
    background-color: #fff;
    border: 1px solid var(--primary);
    color: var(--primary);
    padding: 14px 54px;
    font-size: 14px;
    border-radius: 8px;
}
.btn-edit-no:hover {
    border: 1px solid var(--primary-hover);
}
.wrap-check-email {
    min-height: 100vh;
}
/* Sweet alert */
.swal2-container {
    z-index: 999999 !important;
}
img.img-profile {
    object-fit: cover;
}

/* DETAIL CHAT */
.container.container-detail-chat {
    height: 100%;
}
.container-detail-chat .card-detail-chat {
    padding: 0px 0px 45px 0px;
}
.container-detail-chat .card-detail-chat .header {
    padding: 20px 32px;
    border-bottom: 1px solid #bdbdbd;
}
.container-detail-chat .card-detail-chat .chat-area,
.container-detail-chat .card-detail-chat .form-chat {
    padding: 0px 32px;
}
.container-detail-chat .card-detail-chat .profile {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.container-detail-chat .card-detail-chat .nama {
    font-size: 15px;
    color: #4f4f4f;
}
.container-detail-chat .card-detail-chat .usernama {
    font-size: 12px;
    color: #4f4f4f;
}
.flex-1 {
    flex: 1;
}
.detail-chat-show {
    display: none;
}
.group-detail {
    transition: 0.3s;
    display: none;
}
.group-detail .thumbnail {
    max-height: 250px;
    object-fit: cover;
}
.group-detail.show-group-detail {
    display: block;
}
/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--grey-border);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* NOTIF */
.container-modal-notif {
    max-width: 450px;
}
.container-modal-notif .btn-notif {
    width: 100%;
    border: none;
    font-size: 14px;
    color: var(--bold-blue);
    background: #fff;
    border-top: 1px solid #bdbdbd;
    font-weight: 600;
    transition: 0.3s;
}
.container-modal-notif .btn-notif:hover {
    background-color: #ebeaea;
}
.modal-notif .MuiList-padding {
    padding: 0px;
}
.container-modal-notif .container-content {
    padding: 0px 20px 0px 20px;
}
.container-modal-notif .container-content p {
    font-size: 14px;
}
.container-modal-notif .container-content .item-notif .img-profile {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 40px;
}
.card-notif .img-profile {
    border-radius: 60px;
    width: 60px;
    height: 60px;
}
.card-notif .message {
    font-size: 15px;
}
.card-notif .time {
    font-size: 12px;
    bottom: 5px;
    right: 12px;
    position: absolute;
}
.cursor-pointer {
    cursor: pointer;
}
.w-30 {
    width: 30%;
}
/* Zoom */
#zmmtg-root {
    display: none;
}

#zmmtg-root #wc-content {
    overflow: visible !important;
}

#zmmtg-root #wc-content #wc-footer {
    bottom: 0 !important;
}
input[type='text']:focus.MuiInputBase-input {
    outline: none !important;
    outline-offset: none !important;
}
/* CARD INPUT USER GROUP */
.container-select-user {
    max-height: 300px;
    overflow: auto;
}
.card-input-group-user:hover {
    background-color: var(--primary-light);
    cursor: pointer;
}
.card-input-group-user.active {
    background-color: var(--primary-light);
}
.card-input-group-user .img-profile {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    object-fit: cover;
}
.btn-del-interest {
    width: 25px;
    height: 25px;
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 25px;
    border: 1px solid var(--red);
    background: #fff;
}

/* BTN FRIEND */
.btn-menu-friend {
    border-radius: 50px !important;
    background-color: #fff !important;
    color: var(--grey-border);
    transition: 0.3s;
    flex-direction: column;
    margin: 0 8px !important;
}
.btn-menu-friend .btn-icon {
    display: none;
    margin-right: 2px;
}
.btn-menu-friend p {
    font-size: 12px;
}
.btn-menu-friend.active {
    background-color: var(--primary) !important;
    color: #fff;
}
.rounded-silinder {
    border-radius: 50px !important;
}
.container-stepper {
    background: #ebeaea;
    height: 10px;
    border-radius: 10px;
}
.content-stepper {
    height: 10px;
    background: var(--primary);
    border-radius: 10px;
    width: 10%;
}
.content-stepper.step-one {
    width: 33.4%;
}
.content-stepper.step-two {
    width: 66.8%;
}
.content-stepper.step-three {
    width: 100%;
}
.text-helper {
    font-size: 12px;
    color: var(--grey-text-login);
}
.complete-profile .btn-full {
    width: 100%;
}
.complete-profile .btn-half {
    width: 48%;
}
.container-btn {
    justify-content: center;
}
div input[type='text']:focus {
    outline: 0px solid transparent !important;
    width: 100% !important;
}
.container-input-file {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 11px 16px;
}
.container-input-file.preview {
    padding: 0px 16px;
}
.text-notfound {
    display: flex;
    min-height: 60vh;
    justify-content: center;
    align-items: center;
}
/* LOADING HOME */
.container-loading {
    width: 272px;
}
#teks-loading {
    width: 242px;
    height: 24px;
    animation-name: loadingHome;
    animation-duration: 2s;
    overflow: hidden;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0, 0.25, 0.35, 0.75, 1);
    text-align: left;
}
@keyframes loadingHome {
    0% {
        width: 242px;
    }
    10% {
        width: 242px;
    }
    35% {
        width: 253px;
    }
    75% {
        width: 260px;
    }
    100% {
        width: max-content;
    }
}
.find-a-buddy img {
    width: 180px;
    height: 180px;
    border-radius: 180px;
}
.find-a-buddy .name {
    font-size: 20px;
}
.find-a-buddy .university {
    font-size: 12px;
}
.find-a-buddy img {
    object-fit: cover;
}
.container-matched {
    margin-right: 20px;
    margin-top: 2.5rem;
}
.container-matched img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 3px solid var(--primary);
    background: #fff;
    margin-right: -20px;
}
.chat-conference {
    color: #fff;
    background: var(--primary);
    padding: 4px 20px;
    border-radius: 25px;
}
.chat-conference:hover {
    color: #fff;
    background-color: var(--primary-hover);
    text-decoration: none;
}
/* Header */
.container.container-menu {
    width: max-content;
}
.container.container-menu a {
    color: #fff;
    transition: 0.3s;
}
.container.container-menu a.active {
    color: #fff;
    font-weight: bold;
}
.container.container-menu a:hover {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

::-moz-selection {
    /* Code for Firefox */
    color: #ffffff !important;
    background: var(--primary-hover) !important;
}

::selection {
    color: #ffffff !important;
    background: var(--primary-hover) !important;
}

.rounded-8 {
    border-radius: 8px;
}

.il-success-find {
    width: 300px;
}

.il-search-load {
    width: 100%;
}

.swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
}

footer {
    background-color: var(--primary);
}

footer h3 {
    font-size: 20px;
}

footer .about-app {
    font-size: 14px;
    color: var(--black-text);
}

footer .border-top {
    border-color: #fff !important;
}

footer .copy {
    font-size: 14px;
    color: var(--black-text);
    font-weight: 500;
}
footer .item-footer-link {
    background: rgba(252, 246, 236, 0.89);
    width: 35px;
    height: 35px;
    border-radius: 100%;
}
.input-avatar {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    object-fit: cover;
}
.grid-avatar {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-template-areas: 'area area';
}
.item-avatar {
    flex: 1;
}
.item-avatar img {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    cursor: pointer;
    box-sizing: border-box;
    border: 4px solid #fff;
    transition: 0.2s;
}
.item-avatar.active img {
    border: 4px solid var(--primary);
}
.item-avatar img:hover {
    border: 4px solid var(--primary);
}
.item-avatar h6 {
    font-size: 14px;
}
.img-buddy {
    z-index: 10;
}
.btn_buddy {
    background-color: var(--primary) !important;
    color: #fff !important;
}
.btn_buddy:hover {
    background-color: var(--primary-hover) !important;
}
.btn_back {
  background-color: var(--bold-blue) !important;
  color: #fff !important;
}

.btn_back:hover {
  background-color: var(--bold-blue-hover) !important;
}
.buddy-brand {
    width: 60px;
    height: auto;
    object-fit: cover;
}
.skeleteon-wave {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
a.link-hover {
    color: var(--primary) !important;
}

.il-conf-back {
    z-index: 1;
}

.il-conf-front {
    z-index: 2;
}

@media screen and (max-width: 768px) {
    .il-conf-back {
        top: 0;
    }

    .il-conf-front {
        top: 0;
    }
}

.conf-content {
    z-index: 3;
}

.conf-img-buddy {
    background-color: white;
}

.conf-img-buddy img {
    width: 65px;
    height: auto;
}

.conf-title {
    font-size: 12pt;
}

.conf-checkboxes {
    gap: 0.5rem;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .conf-checkboxes {
      margin-top: 3rem;
    }
  }

@media screen and (min-width: 1024px) {
    .conf-checkboxes {
        margin-top: 1rem;
    }
}

.conf-checkbox {
    height: fit-content;
    cursor: pointer;
}

.conf-checkbox:hover,
.conf-checkbox-selected {
    border: 1px solid #f09856;
    border-radius: 16px;
}

.conf-checkbox-text {
    color: #f09856;
}

.conf-before-input {
    border-right: 1px solid #9f9f9f;
    color: #9f9f9f;
}
