#Zoom-App main {
    width: 70%;
    margin: auto;
    text-align: center;
}

#Zoom-App main button {
    margin-top: 20px;
    background-color: #2d8cff;
    color: #ffffff !important;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    outline: none;
}
.meeting-client-inner .footer-button__button-label {
    color: rgba(255, 255, 255, 0.5) !important;
}

#zmmtg-root .dropdown-toggle::after {
    content: none !important;
}

/* #Zoom-App main button:hover {
    background-color: #2681f2;
}

.join-audio-container .dropdown-menu {
    left: 75px !important;
}

.full-screen-icon .dropdown-menu {
    left: -75px !important;
} */

.full-screen-widget__pop-menu--checked > a::before {
    left: auto !important;
    right: 15px !important;
}
