.collapse {
    display: none;
}
.collapse.in {
    display: block;
}
.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
}
@font-face {
    font-family: 'Glyphicons Halflings';

    src: url('../fonts/glyphicons-halflings-regular.eot');
    src: url('../fonts/glyphicons-halflings-regular.eot?#iefix')
            format('embedded-opentype'),
        url('../fonts/glyphicons-halflings-regular.woff') format('woff'),
        url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'),
        url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular')
            format('svg');
}
.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.glyphicon-asterisk:before {
    content: '\2a';
}
.glyphicon-plus:before {
    content: '\2b';
}
.glyphicon-euro:before {
    content: '\20ac';
}
.glyphicon-minus:before {
    content: '\2212';
}
.glyphicon-cloud:before {
    content: '\2601';
}
.glyphicon-envelope:before {
    content: '\2709';
}
.glyphicon-pencil:before {
    content: '\270f';
}
.glyphicon-glass:before {
    content: '\e001';
}
.glyphicon-music:before {
    content: '\e002';
}
.glyphicon-search:before {
    content: '\e003';
}
.glyphicon-heart:before {
    content: '\e005';
}
.glyphicon-star:before {
    content: '\e006';
}
.glyphicon-star-empty:before {
    content: '\e007';
}
.glyphicon-user:before {
    content: '\e008';
}
.glyphicon-film:before {
    content: '\e009';
}
.glyphicon-th-large:before {
    content: '\e010';
}
.glyphicon-th:before {
    content: '\e011';
}
.glyphicon-th-list:before {
    content: '\e012';
}
.glyphicon-ok:before {
    content: '\e013';
}
.glyphicon-remove:before {
    content: '\e014';
}
.glyphicon-zoom-in:before {
    content: '\e015';
}
.glyphicon-zoom-out:before {
    content: '\e016';
}
.glyphicon-off:before {
    content: '\e017';
}
.glyphicon-signal:before {
    content: '\e018';
}
.glyphicon-cog:before {
    content: '\e019';
}
.glyphicon-trash:before {
    content: '\e020';
}
.glyphicon-home:before {
    content: '\e021';
}
.glyphicon-file:before {
    content: '\e022';
}
.glyphicon-time:before {
    content: '\e023';
}
.glyphicon-road:before {
    content: '\e024';
}
.glyphicon-download-alt:before {
    content: '\e025';
}
.glyphicon-download:before {
    content: '\e026';
}
.glyphicon-upload:before {
    content: '\e027';
}
.glyphicon-inbox:before {
    content: '\e028';
}
.glyphicon-play-circle:before {
    content: '\e029';
}
.glyphicon-repeat:before {
    content: '\e030';
}
.glyphicon-refresh:before {
    content: '\e031';
}
.glyphicon-list-alt:before {
    content: '\e032';
}
.glyphicon-lock:before {
    content: '\e033';
}
.glyphicon-flag:before {
    content: '\e034';
}
.glyphicon-headphones:before {
    content: '\e035';
}
.glyphicon-volume-off:before {
    content: '\e036';
}
.glyphicon-volume-down:before {
    content: '\e037';
}
.glyphicon-volume-up:before {
    content: '\e038';
}
.glyphicon-qrcode:before {
    content: '\e039';
}
.glyphicon-barcode:before {
    content: '\e040';
}
.glyphicon-tag:before {
    content: '\e041';
}
.glyphicon-tags:before {
    content: '\e042';
}
.glyphicon-book:before {
    content: '\e043';
}
.glyphicon-bookmark:before {
    content: '\e044';
}
.glyphicon-print:before {
    content: '\e045';
}
.glyphicon-camera:before {
    content: '\e046';
}
.glyphicon-font:before {
    content: '\e047';
}
.glyphicon-bold:before {
    content: '\e048';
}
.glyphicon-italic:before {
    content: '\e049';
}
.glyphicon-text-height:before {
    content: '\e050';
}
.glyphicon-text-width:before {
    content: '\e051';
}
.glyphicon-align-left:before {
    content: '\e052';
}
.glyphicon-align-center:before {
    content: '\e053';
}
.glyphicon-align-right:before {
    content: '\e054';
}
.glyphicon-align-justify:before {
    content: '\e055';
}
.glyphicon-list:before {
    content: '\e056';
}
.glyphicon-indent-left:before {
    content: '\e057';
}
.glyphicon-indent-right:before {
    content: '\e058';
}
.glyphicon-facetime-video:before {
    content: '\e059';
}
.glyphicon-picture:before {
    content: '\e060';
}
.glyphicon-map-marker:before {
    content: '\e062';
}
.glyphicon-adjust:before {
    content: '\e063';
}
.glyphicon-tint:before {
    content: '\e064';
}
.glyphicon-edit:before {
    content: '\e065';
}
.glyphicon-share:before {
    content: '\e066';
}
.glyphicon-check:before {
    content: '\e067';
}
.glyphicon-move:before {
    content: '\e068';
}
.glyphicon-step-backward:before {
    content: '\e069';
}
.glyphicon-fast-backward:before {
    content: '\e070';
}
.glyphicon-backward:before {
    content: '\e071';
}
.glyphicon-play:before {
    content: '\e072';
}
.glyphicon-pause:before {
    content: '\e073';
}
.glyphicon-stop:before {
    content: '\e074';
}
.glyphicon-forward:before {
    content: '\e075';
}
.glyphicon-fast-forward:before {
    content: '\e076';
}
.glyphicon-step-forward:before {
    content: '\e077';
}
.glyphicon-eject:before {
    content: '\e078';
}
.glyphicon-chevron-left:before {
    content: '\e079';
}
.glyphicon-chevron-right:before {
    content: '\e080';
}
.glyphicon-plus-sign:before {
    content: '\e081';
}
.glyphicon-minus-sign:before {
    content: '\e082';
}
.glyphicon-remove-sign:before {
    content: '\e083';
}
.glyphicon-ok-sign:before {
    content: '\e084';
}
.glyphicon-question-sign:before {
    content: '\e085';
}
.glyphicon-info-sign:before {
    content: '\e086';
}
.glyphicon-screenshot:before {
    content: '\e087';
}
.glyphicon-remove-circle:before {
    content: '\e088';
}
.glyphicon-ok-circle:before {
    content: '\e089';
}
.glyphicon-ban-circle:before {
    content: '\e090';
}
.glyphicon-arrow-left:before {
    content: '\e091';
}
.glyphicon-arrow-right:before {
    content: '\e092';
}
.glyphicon-arrow-up:before {
    content: '\e093';
}
.glyphicon-arrow-down:before {
    content: '\e094';
}
.glyphicon-share-alt:before {
    content: '\e095';
}
.glyphicon-resize-full:before {
    content: '\e096';
}
.glyphicon-resize-small:before {
    content: '\e097';
}
.glyphicon-exclamation-sign:before {
    content: '\e101';
}
.glyphicon-gift:before {
    content: '\e102';
}
.glyphicon-leaf:before {
    content: '\e103';
}
.glyphicon-fire:before {
    content: '\e104';
}
.glyphicon-eye-open:before {
    content: '\e105';
}
.glyphicon-eye-close:before {
    content: '\e106';
}
.glyphicon-warning-sign:before {
    content: '\e107';
}
.glyphicon-plane:before {
    content: '\e108';
}
.glyphicon-calendar:before {
    content: '\e109';
}
.glyphicon-random:before {
    content: '\e110';
}
.glyphicon-comment:before {
    content: '\e111';
}
.glyphicon-magnet:before {
    content: '\e112';
}
.glyphicon-chevron-up:before {
    content: '\e113';
}
.glyphicon-chevron-down:before {
    content: '\e114';
}
.glyphicon-retweet:before {
    content: '\e115';
}
.glyphicon-shopping-cart:before {
    content: '\e116';
}
.glyphicon-folder-close:before {
    content: '\e117';
}
.glyphicon-folder-open:before {
    content: '\e118';
}
.glyphicon-resize-vertical:before {
    content: '\e119';
}
.glyphicon-resize-horizontal:before {
    content: '\e120';
}
.glyphicon-hdd:before {
    content: '\e121';
}
.glyphicon-bullhorn:before {
    content: '\e122';
}
.glyphicon-bell:before {
    content: '\e123';
}
.glyphicon-certificate:before {
    content: '\e124';
}
.glyphicon-thumbs-up:before {
    content: '\e125';
}
.glyphicon-thumbs-down:before {
    content: '\e126';
}
.glyphicon-hand-right:before {
    content: '\e127';
}
.glyphicon-hand-left:before {
    content: '\e128';
}
.glyphicon-hand-up:before {
    content: '\e129';
}
.glyphicon-hand-down:before {
    content: '\e130';
}
.glyphicon-circle-arrow-right:before {
    content: '\e131';
}
.glyphicon-circle-arrow-left:before {
    content: '\e132';
}
.glyphicon-circle-arrow-up:before {
    content: '\e133';
}
.glyphicon-circle-arrow-down:before {
    content: '\e134';
}
.glyphicon-globe:before {
    content: '\e135';
}
.glyphicon-wrench:before {
    content: '\e136';
}
.glyphicon-tasks:before {
    content: '\e137';
}
.glyphicon-filter:before {
    content: '\e138';
}
.glyphicon-briefcase:before {
    content: '\e139';
}
.glyphicon-fullscreen:before {
    content: '\e140';
}
.glyphicon-dashboard:before {
    content: '\e141';
}
.glyphicon-paperclip:before {
    content: '\e142';
}
.glyphicon-heart-empty:before {
    content: '\e143';
}
.glyphicon-link:before {
    content: '\e144';
}
.glyphicon-phone:before {
    content: '\e145';
}
.glyphicon-pushpin:before {
    content: '\e146';
}
.glyphicon-usd:before {
    content: '\e148';
}
.glyphicon-gbp:before {
    content: '\e149';
}
.glyphicon-sort:before {
    content: '\e150';
}
.glyphicon-sort-by-alphabet:before {
    content: '\e151';
}
.glyphicon-sort-by-alphabet-alt:before {
    content: '\e152';
}
.glyphicon-sort-by-order:before {
    content: '\e153';
}
.glyphicon-sort-by-order-alt:before {
    content: '\e154';
}
.glyphicon-sort-by-attributes:before {
    content: '\e155';
}
.glyphicon-sort-by-attributes-alt:before {
    content: '\e156';
}
.glyphicon-unchecked:before {
    content: '\e157';
}
.glyphicon-expand:before {
    content: '\e158';
}
.glyphicon-collapse-down:before {
    content: '\e159';
}
.glyphicon-collapse-up:before {
    content: '\e160';
}
.glyphicon-log-in:before {
    content: '\e161';
}
.glyphicon-flash:before {
    content: '\e162';
}
.glyphicon-log-out:before {
    content: '\e163';
}
.glyphicon-new-window:before {
    content: '\e164';
}
.glyphicon-record:before {
    content: '\e165';
}
.glyphicon-save:before {
    content: '\e166';
}
.glyphicon-open:before {
    content: '\e167';
}
.glyphicon-saved:before {
    content: '\e168';
}
.glyphicon-import:before {
    content: '\e169';
}
.glyphicon-export:before {
    content: '\e170';
}
.glyphicon-send:before {
    content: '\e171';
}
.glyphicon-floppy-disk:before {
    content: '\e172';
}
.glyphicon-floppy-saved:before {
    content: '\e173';
}
.glyphicon-floppy-remove:before {
    content: '\e174';
}
.glyphicon-floppy-save:before {
    content: '\e175';
}
.glyphicon-floppy-open:before {
    content: '\e176';
}
.glyphicon-credit-card:before {
    content: '\e177';
}
.glyphicon-transfer:before {
    content: '\e178';
}
.glyphicon-cutlery:before {
    content: '\e179';
}
.glyphicon-header:before {
    content: '\e180';
}
.glyphicon-compressed:before {
    content: '\e181';
}
.glyphicon-earphone:before {
    content: '\e182';
}
.glyphicon-phone-alt:before {
    content: '\e183';
}
.glyphicon-tower:before {
    content: '\e184';
}
.glyphicon-stats:before {
    content: '\e185';
}
.glyphicon-sd-video:before {
    content: '\e186';
}
.glyphicon-hd-video:before {
    content: '\e187';
}
.glyphicon-subtitles:before {
    content: '\e188';
}
.glyphicon-sound-stereo:before {
    content: '\e189';
}
.glyphicon-sound-dolby:before {
    content: '\e190';
}
.glyphicon-sound-5-1:before {
    content: '\e191';
}
.glyphicon-sound-6-1:before {
    content: '\e192';
}
.glyphicon-sound-7-1:before {
    content: '\e193';
}
.glyphicon-copyright-mark:before {
    content: '\e194';
}
.glyphicon-registration-mark:before {
    content: '\e195';
}
.glyphicon-cloud-download:before {
    content: '\e197';
}
.glyphicon-cloud-upload:before {
    content: '\e198';
}
.glyphicon-tree-conifer:before {
    content: '\e199';
}
.glyphicon-tree-deciduous:before {
    content: '\e200';
}
/* .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
} */
.dropdown {
    position: relative;
}
.dropdown-toggle:focus {
    outline: 0;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}
.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}
.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #428bca;
    outline: 0;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    color: #999;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    cursor: not-allowed;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.open > .dropdown-menu {
    display: block;
}
.open > a {
    outline: 0;
}
.dropdown-menu-right {
    right: 0;
    left: auto;
}
.dropdown-menu-left {
    right: auto;
    left: 0;
}
.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #999;
}
.dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990;
}
.pull-right > .dropdown-menu {
    right: 0;
    left: auto;
}
.dropup .caret {
    content: '';
    border-top: 0;
    border-bottom: 4px solid;
}
.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 1px;
}
.modal-open {
    overflow: hidden;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}
.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%);
}
.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}
.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    outline: none;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}
.modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0;
}
.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: 0.5;
}
.modal-header {
    min-height: 16.42857143px;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}
.modal-header .close {
    margin-top: -2px;
}
.modal-title {
    margin: 0;
    line-height: 1.42857143;
}
.modal-body {
    position: relative;
    padding: 20px;
}
.modal-footer {
    padding: 19px 20px 20px;
    margin-top: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
}
.modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
    margin-left: 0;
}
@media (min-width: 768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto;
    }
    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
    .modal-sm {
        width: 300px;
    }
}
@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}
.tooltip {
    position: absolute;
    z-index: 1030;
    display: block;
    font-size: 12px;
    line-height: 1.4;
    visibility: visible;
    filter: alpha(opacity=0);
    opacity: 0;
}
.tooltip.in {
    filter: alpha(opacity=90);
    opacity: 0.9;
}
.tooltip.top {
    padding: 5px 0;
    margin-top: -3px;
}
.tooltip.right {
    padding: 0 5px;
    margin-left: 3px;
}
.tooltip.bottom {
    padding: 5px 0;
    margin-top: 3px;
}
.tooltip.left {
    padding: 0 5px;
    margin-left: -3px;
}
.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #000;
    border-radius: 4px;
}
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}
.tooltip.top-left .tooltip-arrow {
    bottom: 0;
    left: 5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}
.tooltip.top-right .tooltip-arrow {
    right: 5px;
    bottom: 0;
    border-width: 5px 5px 0;
    border-top-color: #000;
}
.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
}
.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000;
}
.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}
.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    left: 5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}
.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    right: 5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}
.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1010;
    display: none;
    max-width: 276px;
    padding: 1px;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.popover.top {
    margin-top: -10px;
}
.popover.right {
    margin-left: 10px;
}
.popover.bottom {
    margin-top: 10px;
}
.popover.left {
    margin-left: -10px;
}
.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}
.popover-content {
    padding: 9px 14px;
}
.popover > .arrow,
.popover > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.popover > .arrow {
    border-width: 11px;
}
.popover > .arrow:after {
    content: '';
    border-width: 10px;
}
.popover.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, 0.25);
    border-bottom-width: 0;
}
.popover.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: ' ';
    border-top-color: #fff;
    border-bottom-width: 0;
}
.popover.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-width: 0;
}
.popover.right > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: ' ';
    border-right-color: #fff;
    border-left-width: 0;
}
.popover.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}
.popover.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: ' ';
    border-top-width: 0;
    border-bottom-color: #fff;
}
.popover.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, 0.25);
}
.popover.left > .arrow:after {
    right: 1px;
    bottom: -10px;
    content: ' ';
    border-right-width: 0;
    border-left-color: #fff;
}
