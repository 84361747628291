@import url('./style.css');

@media (max-width: 576px) {
    .il-search-load {
        width: 300px;
    }
}

@media only screen and (min-width: 500px) {
    .grid-avatar {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: 'area area area';
    }
}

@media (min-width: 576px) {
    .il-search-load {
        width: 300px;
    }
    footer h3 {
        font-size: 25px;
    }
    .grid-avatar {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-template-areas: 'area area area area';
    }
}

@media only screen and (min-width: 768px) {
    .container-auth {
        display: flex;
    }
    .container-auth .container-ilustration {
        visibility: visible;
        display: flex;
    }
    .container-auth .container-ilustration .wrap-content {
        text-align: center;
        margin-top: 100px;
    }

    .container-auth .text-hallo {
        margin-top: 80px !important;
    }
    .card-profile .container-info {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }
    .container-suggest {
        display: block !important;
    }
    .complete-profile .btn-full,
    .complete-profile .btn-half {
        width: max-content !important;
    }
    .container-btn {
        justify-content: flex-end !important;
    }
    .btn-menu-friend .btn-icon {
        display: block !important;
    }
    .btn-menu-friend p {
        font-size: 15px !important;
    }
    .btn-interest {
        font-size: 16px !important;
    }
    .card-profile .container-info .container-row {
        text-align: left !important;
    }
    .container-home .title {
        font-size: 25px !important;
    }
    .container-home .subtitle {
        font-size: 14px !important;
    }
    .il-search-load {
        width: 300px;
    }
    .item-avatar h6 {
        font-size: 16px;
    }
    .item-avatar img {
        width: 90px;
        height: 90px;
        border-radius: 90px;
    }
    .grid-avatar {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }
    .skeleteon-wave {
        align-items: unset;
        justify-content: unset;
    }
}

@media only screen and (min-width: 769px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 960px) {
    .menu-mobile {
        display: none;
    }
    .card-profile .container-info .img-profile {
        width: 120px !important;
        height: 120px !important;
        border-radius: 120px !important;
    }
    .card-chat .profile {
        width: 60px !important;
        height: 60px !important;
        border-radius: 60px !important;
    }
    .container-detail-chat .card-detail-chat {
        padding: 0px !important;
    }
    .container.container-detail-chat {
        height: 88% !important;
    }
    .container-detail-chat .card-detail-chat .profile {
        width: 50px !important;
        height: 50px !important;
    }
    .detail-chat-show {
        display: block !important;
    }
    .card-friend .container-profile {
        width: 80px !important;
        height: 80px !important;
    }
    .card-friend .img-profile {
        width: 80px !important;
        height: 80px !important;
        border-radius: 80px !important;
    }
    .card-friend .container-content {
        flex-direction: row !important;
        align-items: center !important;
    }
    .card-friend .user-info .name {
        font-size: 18px !important;
    }
    .card-friend .user-info .subtext {
        font-size: 14px !important;
    }
    .card-friend .container-content .btn-card-friend {
        font-size: 15px !important;
    }
    .card-conference {
        flex-direction: row !important;
        align-items: center !important;
    }
    .container-home .title {
        font-size: 30px !important;
    }
    .container-home .subtitle {
        font-size: 16px !important;
    }
    .il-search-load {
        width: 450px;
    }
    .container-home {
        margin-top: 0px !important;
    }
}

@media only screen and (min-width: 1024px) {
    .container-home .title {
        font-size: 35px !important;
    }
}

@media only screen and (min-width: 1440px) {
}

@media only screen and (min-width: 1015px) {
}

@media only screen and (min-width: 1045px) {
}

@media only screen and (min-width: 1280px) {
}

@media only screen and (min-width: 1356px) {
}

/* Max Width */
@media only screen and (max-width: 959.9px) {
    .container.container-detail-chat {
        padding: 0px;
        padding-bottom: 61px;
    }
}

@media only screen and (max-width: 959px) {
    .btn-logout-sm {
        border: 1px solid #eb5757;
        width: 100%;
        padding: 10px 0;
        border-radius: 20px;
        color: #eb5757;
        font-weight: bold;
        margin-bottom: 60px;
    }
}
